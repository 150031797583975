<template>
    <el-dialog v-if="m" title="查看任务" :visible.sync="isShow" width="70%" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-descriptions :column="1">
                    <el-descriptions-item label="任务名称">{{m.name}}</el-descriptions-item>
                    <el-descriptions-item label="任务类型">{{m.typeEnum}}</el-descriptions-item>
                    <el-descriptions-item label="负责人">{{m.users}}</el-descriptions-item>
                    <el-descriptions-item label="状态">{{m.stateDevEnum}}</el-descriptions-item>
                    
                    <el-descriptions-item label="计划完成时间">{{m.planDate}}</el-descriptions-item>
                    

                    <el-descriptions-item label="实际完成时间">{{m.finishDate}}</el-descriptions-item>
                    

                    <el-descriptions-item label="任务描述" :labelStyle="{width: '180px'}" >
                       <div  v-html="m.content"></div>
                    </el-descriptions-item>
                    
                     
                </el-descriptions>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data(){
        return {
            m: {},
            isShow: false
        }
    },
    methods:{
          open: function (data) {
            this.isShow = true;
            if (data.id) {
                this.title = "查看任务";
                this.m = data;
                this.userTags = this.m.users.split(',');
            }
        }
    }
};
</script>

<style>
</style>
        