<template>
    <div class="vue-box">
        <header-project :project="project"></header-project>
        <div class="c-panel">
            <el-form :inline="true" size="mini">
                <el-form-item label="任务名称：">
                    <el-input v-model="p.name" placeholder="模糊查询"></el-input>
                </el-form-item>
                <el-form-item label="研发类型">
                    <input-enum enumName="TaskTypeEnum" v-model="p.type"></input-enum>
                </el-form-item>
                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-search" @click="f5();">查询 </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="add">增加</el-button>
                </el-form-item>
            </el-form>
            <!-- 参数栏 -->
            <!-- <div class="c-title">数据列表</div> -->
            <el-table :data="dataList" size="mini">
               <el-table-column label="序号" prop="id"></el-table-column>
                <el-table-column label="所属产品" prop="productName"></el-table-column>
                <el-table-column label="研发类型" prop="typeEnum"></el-table-column>
                <el-table-column label="任务名称" prop="name"></el-table-column>
                <el-table-column label="项目编码" prop="projectCode"></el-table-column>
                <el-table-column label="指派用户" prop="users"></el-table-column>
                <el-table-column label="状态" prop="stateEnum"></el-table-column>
                <el-table-column label="计划完成时间" prop="planDate"></el-table-column>
                <el-table-column label="实际完成时间" prop="finishDate"></el-table-column>

                <el-table-column prop="address" label="操作" width="220px">
                    <template slot-scope="s">

                        <el-button class="c-btn" type="success" icon="el-icon-view" @click="view(s.row)">查看
                        </el-button>

                        <el-button class="c-btn" type="primary" icon="el-icon-edit" @click="update(s.row)">修改
                        </el-button>
                        <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
                </el-pagination>
            </div>
        </div>

        <!-- 增改组件 -->
        <add-or-update ref="add-or-update"></add-or-update>
        <view-detail ref="view-detail"></view-detail>
        <el-dialog title="选择项目" :visible.sync="isShow" top="10vh" :append-to-body="true" :destroy-on-close="true" custom-class="full-dialog">
            <div class="vue-box">
                <!-- 参数栏 -->
                <div class="c-panel">
                    <el-form size="mini" ref="ruleForm" :model="m" class="demo-ruleForm" label-width="120px">
                        <el-form-item label="选择产品：" prop="productId">
                            <el-select @change="selectChanged" v-model="m.productId">
                                <el-option v-for="item in products" :key="item.id" :value="item.id" :label="item.title"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="产品子项：" prop="productDetialId">
                            <el-select v-model="m.productDetialId">
                                <el-option v-for="item in productDetails" :key="item.id" :value="item.id" :label="item.name"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <span class="c-label">&emsp;</span>
                            <el-button type="primary" icon="el-icon-plus" size="small" @click="ok()">提 交
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import HeaderProject from "../../sa-resources/com-view/header-project.vue";
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import addOrUpdate from "./add.vue";
import viewDetail from "./detail.vue";

export default {
    components: {
        addOrUpdate,
        inputEnum,
        HeaderProject,
        viewDetail
    },
    data() {
        return {
            p: {
                pageSize: 10,
                page: 1,
                type: "",
                category: "",
                name: "",
                state: "",
                projectId: 0
            },
            dataCount: 0,
            dataList: [],
            project: {},
            isShow: false,

            m: {
                productId: 0,
                productDetialId: 0,
                projectId: 0,
                projectCode: ""
            },

            products: [{ id: 0, title: "请选择" }],
            productDetails: [{ id: 0, name: "请选择" }]
        };
    },
    methods: {
        // 数据刷新
        f5: function() {
            this.project = this.sa_admin.params;
            this.m.projectId = this.project.id;
            this.m.projectCode = this.project.code;
            this.p.projectId = this.project.id;
            this.sa.put("/task/listPage", this.p).then(res => {
                this.dataList = res.data.content.map(item => {
                    return item;
                });
                this.dataCount = res.data.totalElements;
            });

            this.sa.get("/product/list").then(res => {
                this.products = res.data;
            });
        },
        // 删除
        del: function(data) {
            this.sa.confirm(
                "是否删除，此操作不可撤销",
                function() {
                    this.sa.delete("/task/delete/" + data.id).then(res => {
                        console.log(res);
                        this.sa.arrayDelete(this.dataList, data);
                        this.sa.ok(res.message);
                    });
                }.bind(this)
            );
        },
        view(row) {
            row.project = this.project;
            this.sa_admin.navigateTo("task-detail", row);
        },
        //更新
        update(row) {
            this.$refs["add-or-update"].open(row);
        },
        //添加
        add: function() {
            /*
            this.$refs["add-or-update"].open({
                projectId: this.project.id,
                category: 1
            });
            */
            this.isShow = true;
        },
        selectChanged: function(e) {
            this.sa.get("/productDetail/list/" + e).then(res => {
                this.productDetails = res.data;
            });
            console.log("选择了", e);
        },
        ok: function() {
            
            this.sa.post("/task/moveProductDetail", this.m).then(res => {
                if (res.code == 200) {
                    this.sa.ok("数据选择成功");
                    this.isShow = false;
                    this.f5();
                }
            });
        }
    },
    created: function() {
        this.f5();
    }
};
</script>

<style>
</style>
